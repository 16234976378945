import { default as indexaUClWzF09lMeta } from "/usr/src/nuxt-app/pages/about-us/index.vue?macro=true";
import { default as dashboardVUKjMJHlVJMeta } from "/usr/src/nuxt-app/pages/admin/dashboard.vue?macro=true";
import { default as newstuRIYHacmJMeta } from "/usr/src/nuxt-app/pages/admin/news.vue?macro=true";
import { default as userswhWQzt0PRyMeta } from "/usr/src/nuxt-app/pages/admin/users.vue?macro=true";
import { default as login6Y49Qe3BVbMeta } from "/usr/src/nuxt-app/pages/auth/login.vue?macro=true";
import { default as indexhXFg3sYFeRMeta } from "/usr/src/nuxt-app/pages/blockchain/index.vue?macro=true";
import { default as indexJBbsGA1qIvMeta } from "/usr/src/nuxt-app/pages/blog/index.vue?macro=true";
import { default as _91id_93GbleYdb99GMeta } from "/usr/src/nuxt-app/pages/careers/contact/[id].vue?macro=true";
import { default as index5e65Opqx57Meta } from "/usr/src/nuxt-app/pages/careers/index.vue?macro=true";
import { default as indexxQqSN0xApAMeta } from "/usr/src/nuxt-app/pages/certifications/index.vue?macro=true";
import { default as indexRBL8TFKg6zMeta } from "/usr/src/nuxt-app/pages/cloud/index.vue?macro=true";
import { default as indexOggkLh9ZdlMeta } from "/usr/src/nuxt-app/pages/cookies-policy/index.vue?macro=true";
import { default as indexc3K3MTvFryMeta } from "/usr/src/nuxt-app/pages/cybersecurity/index.vue?macro=true";
import { default as indexqXyG3csxOHMeta } from "/usr/src/nuxt-app/pages/devops/index.vue?macro=true";
import { default as indexlL5oqNAJvXMeta } from "/usr/src/nuxt-app/pages/fond-bleu/index.vue?macro=true";
import { default as indexAoBMx6PnC1Meta } from "/usr/src/nuxt-app/pages/index.vue?macro=true";
import { default as index1JgM9FMaqNMeta } from "/usr/src/nuxt-app/pages/legal/index.vue?macro=true";
import { default as index9M98SXec9GMeta } from "/usr/src/nuxt-app/pages/network/index.vue?macro=true";
import { default as indexcF9BcfoxGGMeta } from "/usr/src/nuxt-app/pages/support/index.vue?macro=true";
export default [
  {
    name: "about-us___en-US",
    path: "/en-US/about-us",
    component: () => import("/usr/src/nuxt-app/pages/about-us/index.vue")
  },
  {
    name: "about-us___fr-FR",
    path: "/about-us",
    component: () => import("/usr/src/nuxt-app/pages/about-us/index.vue")
  },
  {
    name: "about-us___it-IT",
    path: "/it-IT/about-us",
    component: () => import("/usr/src/nuxt-app/pages/about-us/index.vue")
  },
  {
    name: "admin-dashboard___en-US",
    path: "/en-US/admin/dashboard",
    meta: dashboardVUKjMJHlVJMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/dashboard.vue")
  },
  {
    name: "admin-dashboard___fr-FR",
    path: "/admin/dashboard",
    meta: dashboardVUKjMJHlVJMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/dashboard.vue")
  },
  {
    name: "admin-dashboard___it-IT",
    path: "/it-IT/admin/dashboard",
    meta: dashboardVUKjMJHlVJMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/dashboard.vue")
  },
  {
    name: "admin-news___en-US",
    path: "/en-US/admin/news",
    meta: newstuRIYHacmJMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/news.vue")
  },
  {
    name: "admin-news___fr-FR",
    path: "/admin/news",
    meta: newstuRIYHacmJMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/news.vue")
  },
  {
    name: "admin-news___it-IT",
    path: "/it-IT/admin/news",
    meta: newstuRIYHacmJMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/news.vue")
  },
  {
    name: "admin-users___en-US",
    path: "/en-US/admin/users",
    meta: userswhWQzt0PRyMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/users.vue")
  },
  {
    name: "admin-users___fr-FR",
    path: "/admin/users",
    meta: userswhWQzt0PRyMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/users.vue")
  },
  {
    name: "admin-users___it-IT",
    path: "/it-IT/admin/users",
    meta: userswhWQzt0PRyMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/admin/users.vue")
  },
  {
    name: "auth-login___en-US",
    path: "/en-US/auth/login",
    meta: login6Y49Qe3BVbMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/auth/login.vue")
  },
  {
    name: "auth-login___fr-FR",
    path: "/auth/login",
    meta: login6Y49Qe3BVbMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/auth/login.vue")
  },
  {
    name: "auth-login___it-IT",
    path: "/it-IT/auth/login",
    meta: login6Y49Qe3BVbMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/auth/login.vue")
  },
  {
    name: "blockchain___en-US",
    path: "/en-US/blockchain",
    component: () => import("/usr/src/nuxt-app/pages/blockchain/index.vue")
  },
  {
    name: "blockchain___fr-FR",
    path: "/blockchain",
    component: () => import("/usr/src/nuxt-app/pages/blockchain/index.vue")
  },
  {
    name: "blockchain___it-IT",
    path: "/it-IT/blockchain",
    component: () => import("/usr/src/nuxt-app/pages/blockchain/index.vue")
  },
  {
    name: "blog___en-US",
    path: "/en-US/blog",
    component: () => import("/usr/src/nuxt-app/pages/blog/index.vue")
  },
  {
    name: "blog___fr-FR",
    path: "/blog",
    component: () => import("/usr/src/nuxt-app/pages/blog/index.vue")
  },
  {
    name: "blog___it-IT",
    path: "/it-IT/blog",
    component: () => import("/usr/src/nuxt-app/pages/blog/index.vue")
  },
  {
    name: "careers-contact-id___en-US",
    path: "/en-US/careers/contact/:id()",
    meta: _91id_93GbleYdb99GMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/careers/contact/[id].vue")
  },
  {
    name: "careers-contact-id___fr-FR",
    path: "/careers/contact/:id()",
    meta: _91id_93GbleYdb99GMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/careers/contact/[id].vue")
  },
  {
    name: "careers-contact-id___it-IT",
    path: "/it-IT/careers/contact/:id()",
    meta: _91id_93GbleYdb99GMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/careers/contact/[id].vue")
  },
  {
    name: "careers___en-US",
    path: "/en-US/careers",
    component: () => import("/usr/src/nuxt-app/pages/careers/index.vue")
  },
  {
    name: "careers___fr-FR",
    path: "/careers",
    component: () => import("/usr/src/nuxt-app/pages/careers/index.vue")
  },
  {
    name: "careers___it-IT",
    path: "/it-IT/careers",
    component: () => import("/usr/src/nuxt-app/pages/careers/index.vue")
  },
  {
    name: "certifications___en-US",
    path: "/en-US/certifications",
    component: () => import("/usr/src/nuxt-app/pages/certifications/index.vue")
  },
  {
    name: "certifications___fr-FR",
    path: "/certifications",
    component: () => import("/usr/src/nuxt-app/pages/certifications/index.vue")
  },
  {
    name: "certifications___it-IT",
    path: "/it-IT/certifications",
    component: () => import("/usr/src/nuxt-app/pages/certifications/index.vue")
  },
  {
    name: "cloud___en-US",
    path: "/en-US/cloud",
    component: () => import("/usr/src/nuxt-app/pages/cloud/index.vue")
  },
  {
    name: "cloud___fr-FR",
    path: "/cloud",
    component: () => import("/usr/src/nuxt-app/pages/cloud/index.vue")
  },
  {
    name: "cloud___it-IT",
    path: "/it-IT/cloud",
    component: () => import("/usr/src/nuxt-app/pages/cloud/index.vue")
  },
  {
    name: "cookies-policy___en-US",
    path: "/en-US/cookies-policy",
    component: () => import("/usr/src/nuxt-app/pages/cookies-policy/index.vue")
  },
  {
    name: "cookies-policy___fr-FR",
    path: "/cookies-policy",
    component: () => import("/usr/src/nuxt-app/pages/cookies-policy/index.vue")
  },
  {
    name: "cookies-policy___it-IT",
    path: "/it-IT/cookies-policy",
    component: () => import("/usr/src/nuxt-app/pages/cookies-policy/index.vue")
  },
  {
    name: "cybersecurity___en-US",
    path: "/en-US/cybersecurity",
    component: () => import("/usr/src/nuxt-app/pages/cybersecurity/index.vue")
  },
  {
    name: "cybersecurity___fr-FR",
    path: "/cybersecurity",
    component: () => import("/usr/src/nuxt-app/pages/cybersecurity/index.vue")
  },
  {
    name: "cybersecurity___it-IT",
    path: "/it-IT/cybersecurity",
    component: () => import("/usr/src/nuxt-app/pages/cybersecurity/index.vue")
  },
  {
    name: "devops___en-US",
    path: "/en-US/devops",
    component: () => import("/usr/src/nuxt-app/pages/devops/index.vue")
  },
  {
    name: "devops___fr-FR",
    path: "/devops",
    component: () => import("/usr/src/nuxt-app/pages/devops/index.vue")
  },
  {
    name: "devops___it-IT",
    path: "/it-IT/devops",
    component: () => import("/usr/src/nuxt-app/pages/devops/index.vue")
  },
  {
    name: "fond-bleu___en-US",
    path: "/en-US/fond-bleu",
    component: () => import("/usr/src/nuxt-app/pages/fond-bleu/index.vue")
  },
  {
    name: "fond-bleu___fr-FR",
    path: "/fond-bleu",
    component: () => import("/usr/src/nuxt-app/pages/fond-bleu/index.vue")
  },
  {
    name: "fond-bleu___it-IT",
    path: "/it-IT/fond-bleu",
    component: () => import("/usr/src/nuxt-app/pages/fond-bleu/index.vue")
  },
  {
    name: "index___en-US",
    path: "/en-US",
    component: () => import("/usr/src/nuxt-app/pages/index.vue")
  },
  {
    name: "index___fr-FR",
    path: "/",
    component: () => import("/usr/src/nuxt-app/pages/index.vue")
  },
  {
    name: "index___it-IT",
    path: "/it-IT",
    component: () => import("/usr/src/nuxt-app/pages/index.vue")
  },
  {
    name: "legal___en-US",
    path: "/en-US/legal",
    component: () => import("/usr/src/nuxt-app/pages/legal/index.vue")
  },
  {
    name: "legal___fr-FR",
    path: "/legal",
    component: () => import("/usr/src/nuxt-app/pages/legal/index.vue")
  },
  {
    name: "legal___it-IT",
    path: "/it-IT/legal",
    component: () => import("/usr/src/nuxt-app/pages/legal/index.vue")
  },
  {
    name: "network___en-US",
    path: "/en-US/network",
    component: () => import("/usr/src/nuxt-app/pages/network/index.vue")
  },
  {
    name: "network___fr-FR",
    path: "/network",
    component: () => import("/usr/src/nuxt-app/pages/network/index.vue")
  },
  {
    name: "network___it-IT",
    path: "/it-IT/network",
    component: () => import("/usr/src/nuxt-app/pages/network/index.vue")
  },
  {
    name: "support___en-US",
    path: "/en-US/support",
    component: () => import("/usr/src/nuxt-app/pages/support/index.vue")
  },
  {
    name: "support___fr-FR",
    path: "/support",
    component: () => import("/usr/src/nuxt-app/pages/support/index.vue")
  },
  {
    name: "support___it-IT",
    path: "/it-IT/support",
    component: () => import("/usr/src/nuxt-app/pages/support/index.vue")
  }
]