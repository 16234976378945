<template>
    <main class="relative isolate min-h-full h-screen">
        <UtilsLogoFondOrdinateur class="absolute inset-0 -z-10 h-full w-full object-cover object-top" />
        <div class="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8">
            <p class="text-base font-semibold leading-8 text-white">{{ $t('error.404') }}</p>
            <h1 class="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">{{ $t('error.title') }}</h1>
            <p class="mt-4 text-base text-white/70 sm:mt-6">{{ $t('error.description') }}</p>
            <div class="mt-10 flex justify-center">
                <NuxtLink :to="localePath('/')" class="text-sm font-semibold leading-7 text-white"><span aria-hidden="true">&larr;</span>
                    {{ $t('error.back-home') }}</NuxtLink>
            </div>
        </div>
    </main>
</template>
