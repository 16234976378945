import validate from "/usr/src/nuxt-app/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "careers-middleware": () => import("/usr/src/nuxt-app/middleware/careers-middleware.ts"),
  guest: () => import("/usr/src/nuxt-app/middleware/guest.ts"),
  auth: () => import("/usr/src/nuxt-app/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}