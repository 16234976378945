import revive_payload_client_4sVQNw7RlN from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/nuxt-app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import debug_hAQZccAnsC from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/debug.js";
import payload_client_yVLowv6hDl from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/usr/src/nuxt-app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_gab9g3XuEx from "/usr/src/nuxt-app/node_modules/nuxt-aos/dist/runtime/plugin.js";
import slideovers_X7whXyrIWR from "/usr/src/nuxt-app/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/usr/src/nuxt-app/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/usr/src/nuxt-app/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/usr/src/nuxt-app/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/usr/src/nuxt-app/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_AOuQ1DYzjk from "/usr/src/nuxt-app/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_t2GMTTFnMT from "/usr/src/nuxt-app/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import composition_sLxaNGmlSL from "/usr/src/nuxt-app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/usr/src/nuxt-app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_UYiXMU8ZyN from "/usr/src/nuxt-app/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import aos_u8RxmzeSAw from "/usr/src/nuxt-app/plugins/aos.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  debug_hAQZccAnsC,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_gab9g3XuEx,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  plugin_AOuQ1DYzjk,
  plugin_t2GMTTFnMT,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  plugin_client_UYiXMU8ZyN,
  aos_u8RxmzeSAw
]